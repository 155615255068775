<template>
  <div>
      <vx-card id="searchTable" ref="filterCard" title="Estado de Cuenta de Estudiante" class="user-list-filters mb-8">
          <div class="vx-row">
              <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                  <label class="text-sm opacity-75">Escuela</label>
                  <v-select v-model="idSchool" :clearable="false" :options="schoolOptions" :reduce="idSchool => idSchool.value" v-validate="'required'" data-vv-as="Escuela" name="school" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="handleSelectSchool"/>
                  <span class="text-danger text-sm" v-show="errors.has('school')">{{ errors.first('school') }}</span>
              </div>
              <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                  <label class="text-sm opacity-75">Estudiante</label>
                  <v-select v-model="idStudent" :clearable="true" :options="studentOptions" :reduce="studentOptions => studentOptions.value" data-vv-as="Estudiante" name="student" :dir="$vs.rtl ? 'rtl' : 'ltr'" :disabled="!idSchool"/>
                  <span class="text-danger text-sm" v-show="errors.has('student')">{{ errors.first('student') }}</span>
              </div>
              <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
                  <label class="text-sm opacity-75">Periodo</label>
                  <v-select v-model="idPeriod" :clearable="true" :options="periodOptions" :reduce="periodOptions => periodOptions.value" data-vv-as="Periodo" name="period" :dir="$vs.rtl ? 'rtl' : 'ltr'" :disabled="!idSchool"/>
                  <span class="text-danger text-sm" v-show="errors.has('period')">{{ errors.first('period') }}</span>
              </div>
          </div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mt-3">
                <ul class="demo-alignment">
                  <label class="text-sm opacity-75">Estado del pago</label>
                  <li>
                    <vs-checkbox v-model="statusCheck" vs-value="0">Pagada</vs-checkbox>
                  </li>
                  <li>
                    <vs-checkbox v-model="statusCheck" vs-value="1">Morosa</vs-checkbox>
                  </li>
                  <li>
                    <vs-checkbox v-model="statusCheck" vs-value="2">Vigente</vs-checkbox>
                  </li>
                </ul>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mt-3">
                <ul class="demo-alignment">
                  <label class="text-sm opacity-75">Medio de pago</label>
                  <li>
                    <vs-checkbox v-model="paymentMethodCheck" vs-value="1">Tiptap</vs-checkbox>
                  </li>
                  <li>
                    <vs-checkbox v-model="paymentMethodCheck" vs-value="0">Otro</vs-checkbox>
                  </li>
                </ul>
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mt-5 ag-grid-table-actions-right">
                <vs-button id="searchButton" class="mb-4 md:mb-0" @click="loadAccountTable" :disabled="!(isFormValid() && isFilterActive)">Aplicar Filtros</vs-button>
            </div>
          </div>
      </vx-card>

      <div class="vx-card p-6">
          <!-- TABLE ACTION ROW -->
          <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
              <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                  class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                  <span class="mr-2"
                  >{{
                      currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                      accounts.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : accounts.length
                  }}
                  de {{ accounts.length }}</span
                  >
                  <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                  </vs-dropdown-item>
              </vs-dropdown-menu>
              </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div
              class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
              <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Buscar..."
              />
              <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
              >Exportar</vs-button
              >
          </div>
          </div>
          <ag-grid-vue
              ref="agGridTable"
              :gridOptions="gridOptions"
              class="ag-theme-material w-100 my-4 ag-grid-table"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="accounts"
              rowSelection="single"
              colResizeDefault="shift"
              :animateRows="true"
              :pagination="true"
              :paginationPageSize="paginationPageSize"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl"
              :modules="modules"
              @first-data-rendered="onFirstDataRendered"
          >
          <!-- @selection-changed="onSelectionChanged" -->
          </ag-grid-vue>
          <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
          />
      </div>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import { AgGridVue } from "@ag-grid-community/vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import reportModule from "@/store/report/moduleReport.js";
import moduleDataListInstitutions from "@/store/data-list-institutions/moduleDataList.js"
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";
import moduleServices from '@/store/services/moduleServices.js'
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import CellRendererPaymentStatus from './components/CellRendererPaymentStatus.vue';

export default {
  components: {
      vSelect,
      AgGridVue,
      AllCommunityModules,

      // Cell Renderers
      CellRendererPaymentStatus
  },
  data() {
      return {
          idSchool: null,
          idStudent: null,
          idPeriod: null,
          accounts: [],
          schoolOptions: [],
          studentOptions: [],
          periodOptions: [],
          statusCheck: [],
          paymentMethodCheck: [],
          // payment_status
          // payment_date
          // amount
          // charges
          // discounts
          // final_amount
          // payment_type
          mockData: [
              {
                  "school_name": "Escuela numero 32",
                  "id": 2478,
                  "student_display_name": "Carlos Alberto Mocilnik Perez",
                  "guardian_display_name": "Juan Manuel Mocilnik Perez",
                  "gop_status": "Sincronizado",
                  "period": "Septiembre 2024",
                  "payment_status": "Pagada",
                  "status": 2,
                  "payment_date": "08/06/2023",
                  "expiration_date": "10/11/2024",
                  "debt_days": 180,
                  "amount": 500,
                  "charges": 35,
                  "discounts": 0,
                  "final_amount": 535,
                  "payment_type": "Tiptap"
              },
              {
                  "school_name": "Escuela numero 32",
                  "id": 2478,
                  "student_display_name": "Carlos Alberto Mocilnik Perez",
                  "guardian_display_name": "Juan Manuel Mocilnik Perez",
                  "gop_status": "Sincronizado",
                  "period": "Septiembre 2024",
                  "payment_status": "Pagada",
                  "status": 1,
                  "payment_date": "08/06/2023",
                  "expiration_date": "10/11/2024",
                  "debt_days": 180,
                  "amount": 500,
                  "charges": 35,
                  "discounts": 0,
                  "final_amount": 535,
                  "payment_type": "Tiptap"
              },
              {
                  "school_name": "Escuela numero 32",
                  "id": 2478,
                  "student_display_name": "Carlos Alberto Mocilnik Perez",
                  "guardian_display_name": "Juan Manuel Mocilnik Perez",
                  "gop_status": "Sincronizado",
                  "period": "Septiembre 2024",
                  "payment_status": "Pagada",
                  "status": 1,
                  "payment_date": "08/06/2023",
                  "expiration_date": "10/11/2024",
                  "debt_days": 180,
                  "amount": 500,
                  "charges": 35,
                  "discounts": 0,
                  "final_amount": 535,
                  "payment_type": "Tiptap"
              },
              {
                  "school_name": "Escuela numero 32",
                  "id": 2478,
                  "student_display_name": "Carlos Alberto Mocilnik Perez",
                  "guardian_display_name": "Juan Manuel Mocilnik Perez",
                  "gop_status": "Sincronizado",
                  "period": "Septiembre 2024",
                  "payment_status": "Pagada",
                  "status": 1,
                  "payment_date": "08/06/2023",
                  "expiration_date": "10/11/2024",
                  "debt_days": 180,
                  "amount": 500,
                  "charges": 35,
                  "discounts": 0,
                  "final_amount": 535,
                  "payment_type": "Tiptap"
              },
              {
                  "school_name": "Escuela numero 32",
                  "id": 2478,
                  "student_display_name": "Carlos Alberto Mocilnik Perez",
                  "guardian_display_name": "Juan Manuel Mocilnik Perez",
                  "gop_status": "Sincronizado",
                  "period": "Septiembre 2024",
                  "payment_status": "Pagada",
                  "status": 1,
                  "payment_date": "08/06/2023",
                  "expiration_date": "10/11/2024",
                  "debt_days": 180,
                  "amount": 500,
                  "charges": 35,
                  "discounts": 0,
                  "final_amount": 535,
                  "payment_type": "Tiptap"
              },
          ],

          perdiodMockData: [
              { label: 'Julio 2023', value: '1' },
              { label: 'Agosto 2023', value: '1' },
              { label: 'Septiembre 2023', value: '1' },
              { label: 'Octubre 2023', value: '1' },
              { label: 'Noviembre 2023', value: '1' },
          ],

          //AgGrid
          modules: AllCommunityModules,
          searchQuery: "",
          maxPageNumbers: 5,
          gridApi: null,
          defaultColDef: {
              sortable: true,
              editable: false,
              resizable: true,
              suppressMenu: true,
          },
          gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: false,
              floatingFiltersHeight: 0,
          },
          columnDefs: [
              {
                  headerName: "Escuela",
                  sortable: true,
                  field: "school_name",
                  width: 120,
                  filter: true,
                  // cellClass: "cellMayusc",
              },
              {
                  headerName: "Estudiante",
                  field: "student_display_name",
                  width: 240,
                  filter: true,
                  cellClass: "cellMayusc",
              },
              {
                  headerName: "Documento",
                  field: "student_document",
                  width: 240,
                  filter: true,
                  cellClass: "cellMayusc",
              },
              {
                  headerName: "Acudiente",
                  field: "guardian_display_name",
                  width: 240,
                  filter: true,
                  cellClass: "cellMayusc",
              },
              {
                  headerName: "ID Acudiente",
                  field: "id_guardian",
                  filter: true,
              },
              {
                  headerName: "Cuota",
                  field: "period",
                  width: 140,
                  filter: true,
                  // valueFormatter: params => this.$options.filters.currencySign(params.data.amount, '$ '),
              },
              {
                  headerName: 'Fecha de vencimiento',
                  field: 'expiration_date',
                  filter: false,
                  width: 150,
                  comparator: this.myDateComparator,
              },
              {
                  headerName: "Estado",
                  field: "payment_status",
                  width: 140,
                  filter: true,
                  cellRendererFramework: 'CellRendererPaymentStatus'
                  // valueFormatter: params => this.$options.filters.currencySign(params.data.month_amount, '$ '),
              },
              {
                  headerName: 'Fecha de pago',
                  field: 'payment_date',
                  filter: false,
                  width: 150,
                  comparator: this.myDateComparator,
              },
              {
                  headerName: "Monto",
                  field: "amount",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.amount, '$ '),
              },
              {
                  headerName: "Penalidad",
                  field: "charges",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.charges, '$ '),
              },
              {
                  headerName: "Descuentos",
                  field: "discounts",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.discounts, '$ '),
              },
              {
                  headerName: "Total",
                  field: "final_amount",
                  width: 150,
                  filter: true,
                  valueFormatter: params => this.$options.filters.currencySign(params.data.final_amount, '$ '),
              },
              {
                  headerName: 'Medio de pago',
                  field: 'payment_type',
                  filter: true,
                  width: 150,
              },
          ],
      }
  },
  methods: {
      loadAccountTable() {
          this.$validator.validateAll().then(result => {
              if(!result) return

              this.accounts = []

              if (this.idSchool > 0) {
                  this.$store.dispatch("reportModule/getStudentsAccount", this.getQueryParams())
                  .then((response) => {
                      if (response.data && response.data.length > 0) {
                        let defaultings = JSON.parse(JSON.stringify(response.data));

                        defaultings.forEach(e => {
                            e.student_display_name = `${e.student_last_name} ${e.student_name}`
                            e.guardian_display_name = `${e.guardian_last_name} ${e.guardian_name}`
                            e.payment_date = e.payment_date ? new Date(e.payment_date).toLocaleDateString('es-AR', this.$localeDateOptions) : '';
                            e.expiration_date = e.expiration_date ? new Date(e.expiration_date).toLocaleDateString('es-AR', this.$localeDateOptions) : '';
                            e.period = this.resolveLabelPeriodTable(e.fee)
                        })

                        this.accounts = defaultings;
                      }
                      // this.accounts = this.mockData
                  })
                  .catch((error) => {
                      console.log(error);
                  });
              }
          })
      },
      getQueryParams() {
          let params = `id_school=${this.idSchool}&payment_status=${this.statusCheck.toString()}`

          if (this.idPeriod) params += `&period='${this.idPeriod}'`
          if (this.idStudent) params += `&id_pupil='${this.idStudent}'`
          if (this.paymentMethodCheck.length > 0) params += `&payment_type=${this.paymentMethodCheck.toString()}`

          return params
      },
      getSchools() {
          if(this.schoolOptions && this.schoolOptions.length) return

          this.$store.dispatch(`dataListInstitutions/getSchools`)
          .then(() => {
              const schools = this.$store.getters['dataListInstitutions/getSchoolsInGop'];
              schools.forEach(element => {
                  this.schoolOptions.push({label: element.name, value: element.id})
              });
          })
          .catch(err => console.log(err))

      },
      myDateComparator(date1, date2) {
          var date1Number = this.monthToComparableNumber(date1);
          var date2Number = this.monthToComparableNumber(date2);
          if (date1Number === null && date2Number === null) {
              return 0;
          }
          if (date1Number === null) {
              return -1;
          }
          if (date2Number === null) {
              return 1;
          }
          return date1Number - date2Number;
      },
      monthToComparableNumber(date) {
          if (date === undefined || date === null || date.length !== 10) {
              return null;
          }
          var yearNumber = date.substring(6, 10);
          var monthNumber = date.substring(3, 5);
          var dayNumber = date.substring(0, 2);
          var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
          return result;
      },
      resolveLabelPeriod(datePeriod) {
        const p = new Date(datePeriod)
        return `${p.toLocaleDateString('en-US', {month: 'long'})} ${p.getFullYear()}`
      },
      // period as object
      resolveLabelPeriodOptions(period) {
        const datePeriod = new Date(period.year, period.month -1, 1)

        const periodOption = { label: this.resolveLabelPeriod(datePeriod), value: `${period.year}-${period.month.toString().length == 1 ? '0' + period.month : period.month}` }

        return periodOption
      },
      resolveLabelPeriodTable(strPeriod) {
        // Str Period like '2023-06'
        if (!strPeriod) return

        const p = strPeriod.split('-')
        return this.resolveLabelPeriod(new Date(p[0], p[1] - 1, 1))
      },
      getPeriodsBySchool() {
        if (!this.idSchool) return

        this.$store.dispatch(`servicesModule/getSchoolAcademicPeriodBySchool`, this.idSchool)
        .then(r => {
          if (!r.data.status) return

          const periods = r.data.school_academic_period_fees

          this.periodOptions = periods.map(p => this.resolveLabelPeriodOptions(p))
        })
        .catch(err => console.log(err))

      },
      getStudentsBySchool() {
        this.resetDependingFilters()

        if(!this.idSchool) return

          // this.accounts = this.mockData

          this.$store.dispatch(`dataListInstitutions/getPupilsBySchool`, this.idSchool)
          .then(response => {
              const students = response.data;

              students.forEach(element => {
                  this.studentOptions.push({label: `${element.name} ${element.last_name} (${element.id})`, value: element.id})
              });
          })
          .catch(err => console.log(err))
      },
      handleSelectSchool() {
        this.getStudentsBySchool()
        this.getPeriodsBySchool()
      },
      isFormValid() {
        return !this.errors.any() && (this.idSchool > 0)
      },
      updateSearchQuery(val) {
        this.gridApi.setQuickFilter(val);
      },
      onFirstDataRendered: function() {
          this.gridColumnApi.autoSizeAllColumns();
      },
      resetDependingFilters() {
        this.idStudent = null
        this.idPeriod = null
        this.studentOptions = []
        this.periodOptions = []
      }
  },
  computed: {
      paginationPageSize() {
          if (this.gridApi) return this.gridApi.paginationGetPageSize();
          else return 50;
      },
      totalPages() {
          if (this.gridApi) return this.gridApi.paginationGetTotalPages();
          else return 0;
      },
      currentPage: {
          get() {
              if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
              else return 1;
          },
          set(val) {
              this.gridApi.paginationGoToPage(val - 1);
          },
      },
      isFilterActive() {
        return (this.idStudent || this.idPeriod);
      }
  },
  created() {
    // Register Service Module
    if(!moduleServices.isRegistered) {
      this.$store.registerModule('servicesModule', moduleServices)
      moduleServices.isRegistered = true
    }
    if (!reportModule.isRegistered) {
        this.$store.registerModule("reportModule", reportModule);
        reportModule.isRegistered = true;
    }
    if(!moduleDataListInstitutions.isRegistered) {
        this.$store.registerModule('dataListInstitutions', moduleDataListInstitutions)
        moduleDataListInstitutions.isRegistered = true
    }
    if (!moduleUserManagement.isRegistered) {
        this.$store.registerModule("userManagement", moduleUserManagement);
        moduleUserManagement.isRegistered = true;
    }

    this.getSchools();
    // this.loadAccountTable();
  },
  mounted() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;

      if (this.$vs.rtl) {
          const header = this.$refs.agGridTable.$el.querySelector(
              ".ag-header-container"
          );
          header.style.left =
              "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
      }
  }
}
</script>
<style>
.cellMayusc {
  text-transform: capitalize;
}
.ag-grid-table.total .ag-row {
  font-weight: 700;
}
.ag-grid-table.total .ag-header {
  border: none;
}
</style>
